import React, { useEffect, useState, useContext } from "react";
import "./index.scss";
import { DatePicker, Button, message, Tooltip, Divider } from "antd";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import TeamTree from "./components/teamtree";
import { AuthContext } from "contexts/authContext";
import { UserAction, TeamAction } from "../../redux/store/action";
import UserTree from "./components/usertree";
import { GetTreeNode } from "utils/getTreeNodes";
import { permissionsMap } from "constants/constant";
import { FiSettings } from "react-icons/fi";
import { SETTINGS } from "route/constant";
import { useNavigate } from "react-router-dom";
import CheckboxComponent from "./components/checkbox";
import {
  setDate,
  setEndDate,
  setMonth,
  setStartDate,
  setTeamIds,
  setUserIds as setPinnedUserIds,
  setDatePin,
  setTeamUserPin,
  setTeamParentIds,
} from "redux/store/filters/action";
import { BsPinAngle, BsPinAngleFill } from "react-icons/bs";
dayjs.extend(customParseFormat);
const { RangePicker, MonthPicker } = DatePicker;

const rangePresets = [
  {
    label: "Today",
    value: [dayjs().startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(1, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-6, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-13, "d"), dayjs()],
  },
  {
    label: "Last 31 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
];

const rangePresetsCrossDay = [
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(1, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "Last 7 Days",
    value: [
      dayjs().subtract(7, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "Last 14 Days",
    value: [
      dayjs().subtract(14, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "Last 31 Days",
    value: [
      dayjs().subtract(31, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
];

const Filter = (props) => {
  const {
    modelName,
    teamshow,
    usershow,
    monthshow,
    rangeshow,
    btnDownload,
    ExportDownload,
    btnDownloadPdf,
    DownloadPdf,
    btnReload,
    date,
    month,
    startDate,
    endDate,
    monthpikershow = false,
    loadingbtn = false,
    loadingBtnPdf = false,
    btnAlertSettings,
    singleSelect,
    selectedUserIds,
    children,
    isAppUrl,
    checkboxOptions,
    checkboxDefaultValue,
    appUrlOnChaneType,
    prefixComponents,
    prefixTimePickerComponents,
    prefixTimeCalComponents,
    singleSelectReload,
    futureDateDisable = true,
    reportType,
    updateAllFilters = () => {
      return true;
    },
  } = props || {};

  const {
    team_list_success,
    team_list_data,
    team_list_failed,
    team_list_message,
  } = useSelector((state) => state.team);
  const { KState, checkRequiredPermissions } = useContext(AuthContext);
  const [teamTreeData, setTeamTreeData] = useState([]);
  const {
    medetails_data,
    user_list_processing,
    user_list_success,
    user_list_data,
    user_list_failed,
    team_wise_user_list_message,
  } = useSelector((state) => state.users);

  const {
    teamUserPin,
    datePin,
    teamUserPinDate,
    datePinDate,
    date: pinnedDate,
    startDate: pinnedStartDate,
    endDate: pinnedEndDate,
    month: pinnedMonth,
    userIds: pinnedUserIds,
    teamIds: pinnedTeamIds,
    teamParentIds: pinnedTeamParentIds,
  } = useSelector((state) => state.filters);
  const [userNodes, setuserNodes] = useState([]);
  const [isReload, setisReload] = useState(false);
  const [initialMonth, setInitialMonth] = useState(dayjs());
  const [initialDate, setInitialDate] = useState(dayjs());
  const [sDate, setsDate] = useState(dayjs().subtract(6, "day"));
  const [eDate, seteDate] = useState(dayjs());
  const [isActive, setActive] = useState(false);
  const [selectedTeamParentIds, setSelectedTeamParentIds] = useState([]);
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);
  const [allTeamSelect, setAllTeamSelect] = useState(false);

  const rangActive = false;
  const dispatch = useDispatch();

  const GetTeamList = () => {
    if (KState && KState.keycloak) {
      const data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
      };
      dispatch(TeamAction.TeamListProcess(data));
    }
  };

  const GetUserList = () => {
    if (KState && KState.keycloak) {
      const data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
        isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
      };

      dispatch(UserAction.UserListProcess(data));
    }
  };

  // team data
  useEffect(() => {
    if (!user_list_success && !user_list_processing) {
      dispatch(UserAction.TeamWiseUserListInitial());
      GetUserList();
    }
    if (!team_list_success) {
      dispatch(TeamAction.TeamListInitial());
      GetTeamList();
    }
  }, []);

  useEffect(() => {
    if (team_list_success) {
      setisReload(false);
      if ((team_list_data || []).length > 0) {
        const groupID = medetails_data?.attached_group?.id;

        const treeGroupData = checkRequiredPermissions([
          permissionsMap.TENANT_OWNER,
        ])
          ? GetTreeNode(team_list_data)
          : [
              {
                title: medetails_data?.attached_group?.name,
                value: groupID,
                children: GetTreeNode(team_list_data, groupID),
              },
            ];

        setTeamTreeData(treeGroupData);
      }
    } else if (team_list_failed) {
      setisReload(false);
      // message.error(team_list_message?.message);
    }
  }, [team_list_success, team_list_data, team_list_failed, team_list_message]);

  const getUserTreeNode = (userList) => {
    const ulist = [];
    const uName = [];
    const uIds = [];
    let id = "";
    let fullname = "";

    userList.forEach((item) => {
      id = item?.identity?.id;
      fullname = item?.identity?.first_name + " " + item?.identity?.last_name;
      uIds.push(id);
      uName.push(fullname);
      ulist.push({
        title: fullname,
        children: null,
        value: id,
      });
    });

    setuserNodes(ulist);

    setisReload(false);
  };

  useEffect(() => {
    if (user_list_success === true) {
      if ((user_list_data || []).length > 0) {
        getUserTreeNode(user_list_data);
      } else if (user_list_failed === true) {
        // message.error(team_wise_user_list_message?.message);

        setuserNodes([]);

        setisReload(false);
      }
    }
  }, [
    user_list_success,
    user_list_failed,
    team_wise_user_list_message,
    user_list_failed,
  ]);

  const selectUsers = (val) => {
    console.log("selectedUsers", val);
    // filterDataPass && filterDataPass(val);
    updateAllFilters({
      teamUserPin,
      datePin,
      filterUserIds: val,
      userChange: true,
    });
    highlightPin("team-user-pin");
    if (teamUserPin && !singleSelect) {
      dispatch(setPinnedUserIds(val));
    }
  };

  const selectteam = (selectedTeam) => {
    console.log("selectedTeam", selectedTeam);
    // selectTeam(selectedTeam);
    setSelectedTeamIds(selectedTeam);
    updateAllFilters({
      teamUserPin,
      datePin,
      filterTeamIds: selectedTeam,
      filterUserIds: [],
      teamChange: true,
    });

    highlightPin("team-user-pin");
    if (teamUserPin) {
      dispatch(setTeamIds(selectedTeam));

      dispatch(setPinnedUserIds([]));
    }
    if ((selectedTeam || []).length > 0) {
      getUserTreeNode(
        user_list_data.filter((user) =>
          selectedTeam.includes(user.attached_group.id)
        )
      );
    } else if ((selectedTeam || []).length === 0) {
      getUserTreeNode(user_list_data);
    }
  };

  const selectTeamParent = (selectedTeamParent) => {
    console.log("selectedTeamParent", selectedTeamParent);
    setSelectedTeamParentIds(selectedTeamParent);
    if (teamUserPin) {
      dispatch(setTeamParentIds(selectedTeamParent));
    }
  };

  const onDateChange = (date) => {
    console.log("date", date);
    setActive(!isActive);
    // onSelectDate(date || dayjs(), dateStrings || dayjs().toISOString());
    // setInitialDate(date || dayjs());

    updateAllFilters({
      teamUserPin,
      datePin,
      filterDate: date,
      dateChange: true,
    });
    highlightPin("date-pin");
    if (datePin) {
      dispatch(setDate(date));
    }
  };

  const onDateRangeChange = (dates, dateStrings) => {
    console.log("dateStrings", dateStrings);
    const startDate = dayjs(_.nth(dateStrings, 0));
    const endDate = dayjs(_.nth(dateStrings, 1));
    const Daydiff = dayjs(endDate).diff(dayjs(startDate), "days");
    if (Daydiff > 31) {
      message.warning("Max select date range 31 days");
    } else if (dates) {
      // dateRange(startDate, endDate);
      updateAllFilters({
        teamUserPin,
        datePin,
        filterStartDate: startDate,
        filterEndDate: endDate,
        dateRangeChange: true,
      });
      highlightPin("date-pin");
      if (datePin) {
        dispatch(setStartDate(startDate));
        dispatch(setEndDate(endDate));
      }
    }
  };

  const onRefresh = () => {
    console.log("refresh");

    updateAllFilters({
      teamUserPin,
      datePin,
      refresh: true,
    });
  };

  const onReload = () => {
    console.log("reload");
    setisReload(true);

    // setsDate(sdate);
    // seteDate(edate);
    // setInitialDate(dayjs());
    // setSelectedTeamIds([]);
    // ClickReload(true);

    updateAllFilters({
      teamUserPin,
      datePin,
      filterDate: dayjs(),
      filterStartDate:
        reportType === "crossday"
          ? dayjs().subtract(7, "d")
          : dayjs().subtract(6, "d"),
      filterEndDate:
        reportType === "crossday" ? dayjs().subtract(1, "d") : dayjs(),
      filterMonthStart: dayjs().startOf("month"),
      filterMonthEnd: dayjs().endOf("month"),
      filterUserIds: [],
      filterTeamIds: [],
      reload: true,
    });

    setSelectedTeamIds([]);
    setSelectedTeamParentIds([]);

    if (datePin) {
      dispatch(setDate(dayjs()));
      dispatch(setStartDate(dayjs().subtract(6, "d")));
      dispatch(setEndDate(dayjs()));
      dispatch(setMonth(dayjs()));
    }
    if (teamUserPin) {
      dispatch(setTeamIds([]));
      dispatch(setTeamParentIds([]));
      dispatch(setPinnedUserIds([]));
    }
  };
  function disableCurrent(current) {
    return current && current >= dayjs().startOf("day");
  }

  function disabledDate(current) {
    const weekStart = dayjs().subtract(1, "year");
    const weekEnd =
      modelName === "timeline" || modelName === "screenshot"
        ? dayjs().add(1, "day")
        : dayjs();
    return !(weekStart.isBefore(current) && weekEnd.isAfter(current));
  }

  const onMonthChange = (monthDate) => {
    console.log("monthDate", monthDate);
    // const sdate = dayjs(monthDate || Date.now()).startOf("month");
    // const edate = dayjs(monthDate || Date.now()).endOf("month");
    // OnSelctMonth(sdate, edate);

    updateAllFilters({
      teamUserPin,
      datePin,
      filterMonthStart: dayjs(monthDate || Date.now()).startOf("month"),
      filterMonthEnd: dayjs(monthDate || Date.now()).endOf("month"),
      monthChange: true,
    });
    highlightPin("date-pin");

    if (datePin) {
      dispatch(setMonth(monthDate));
    }
  };

  const navigate = useNavigate();
  const handleAction = () => {
    navigate(SETTINGS, { state: { pathKey: "alertRules" } });
  };

  const appUrlOnChange = (value) => {
    appUrlOnChaneType(value);
  };

  useEffect(() => {
    console.log("reset");

    let datePinStale = false;
    let teamUserPinStale = false;

    if (datePin && new Date(datePinDate).getDay() !== new Date().getDay()) {
      onDatePin();
      datePinStale = true;
    }

    if (
      teamUserPin &&
      new Date(teamUserPinDate).getDay() !== new Date().getDay()
    ) {
      onTeamUserPin();
      teamUserPinStale = true;
    }

    if (teamUserPin && !teamUserPinStale) {
      setSelectedTeamIds(pinnedTeamIds);
      setSelectedTeamParentIds(pinnedTeamParentIds);
    } else {
      setSelectedTeamIds([]);
      setSelectedTeamParentIds([]);
    }

    updateAllFilters({
      initialized: true,
      teamUserPin,
      datePin,
      filterDate:
        datePin && pinnedDate && !datePinStale
          ? dayjs(pinnedDate)
          : startDate || dayjs(),
      filterStartDate:
        datePin && pinnedStartDate && !datePinStale
          ? dayjs(pinnedStartDate)
          : startDate || dayjs().subtract(6, "d"),
      filterEndDate:
        datePin && pinnedEndDate && !datePinStale
          ? dayjs(pinnedEndDate)
          : endDate || dayjs(),
      filterMonthStart:
        datePin && pinnedMonth && !datePinStale
          ? dayjs(pinnedMonth).startOf("month")
          : dayjs(month || dayjs()).startOf("month"),
      filterMonthEnd:
        datePin && pinnedMonth && !datePinStale
          ? dayjs(pinnedMonth).endOf("month")
          : dayjs(month || dayjs()).endOf("month"),
      filterUserIds:
        teamUserPin && pinnedUserIds && !teamUserPinStale
          ? pinnedUserIds
          : selectedUserIds || [],
      filterTeamIds:
        teamUserPin && pinnedTeamIds && !teamUserPinStale ? pinnedTeamIds : [],
    });

    // if (pinnedDate && onSelectDate && monthshow) {
    //   onSelectDate(dayjs(pinnedDate), dayjs(pinnedDate).toISOString());
    // }
    // if (pinnedStartDate && pinnedEndDate && dateRange && rangeshow) {
    //   dateRange(dayjs(pinnedStartDate), dayjs(pinnedEndDate));
    // }
    // if (pinnedMonth && OnSelctMonth && monthpikershow) {
    //   OnSelctMonth(
    //     dayjs(pinnedMonth).startOf("month"),
    //     dayjs(pinnedMonth).endOf("month")
    //   );
    // }
    // if (pinnedUserIds && filterDataPass && !singleSelect && usershow) {
    //   console.log("pinnedUserIds", pinnedUserIds);
    //   filterDataPass(pinnedUserIds);
    // }
    if (
      teamUserPin &&
      !teamUserPinStale &&
      pinnedTeamIds &&
      teamshow &&
      usershow
    ) {
      // selectTeam(pinnedTeamIds);
      if ((pinnedTeamIds || []).length > 0) {
        getUserTreeNode(
          user_list_data.filter((user) =>
            pinnedTeamIds.includes(user.attached_group.id)
          )
        );
      } else {
        getUserTreeNode(user_list_data);
      }
    }
  }, [
    monthshow,
    rangeshow,
    monthpikershow,
    teamshow,
    usershow,
    user_list_success,
  ]);

  const onDatePin = () => {
    if (datePin) {
      dispatch(setDatePin(false));
      dispatch(setDate(null));
      dispatch(setStartDate(null));
      dispatch(setEndDate(null));
      dispatch(setMonth(null));
    } else {
      dispatch(setDatePin(true));
      dispatch(setDate(dayjs(date || initialDate)));
      dispatch(setStartDate(dayjs(startDate || sDate)));
      dispatch(setEndDate(dayjs(endDate || eDate)));
      dispatch(setMonth(dayjs(month || initialMonth)));
    }
  };

  const onTeamUserPin = () => {
    if (teamUserPin) {
      dispatch(setTeamUserPin(false));

      dispatch(setPinnedUserIds([]));
      dispatch(setTeamIds([]));
      dispatch(setTeamParentIds([]));
    } else {
      dispatch(setTeamUserPin(true));

      dispatch(setTeamIds(selectedTeamIds || []));
      dispatch(setTeamParentIds(selectedTeamParentIds || []));
      dispatch(setPinnedUserIds(selectedUserIds || []));
    }
  };

  const highlightPin = (pinId) => {
    if (pinId) {
      document.getElementById(pinId)?.classList.add("highlight");
      setTimeout(() => {
        document.getElementById(pinId)?.classList.remove("highlight");
      }, 1000);
    }
  };
  const disableMonth = (current) => {
    if (modelName !== "leave") {
      // Disable current and future months
      // return current && current >= dayjs().startOf("month");
      return current && current >= dayjs().startOf("month");
    }

    // Enable all months if modelName is "leave"
    return false;
  };
  return (
    <div className="filter">
      <div className="filter_data ">
        <div className="f_start">
          {teamshow ? (
            (teamTreeData || []).length > 0 ? (
              <TeamTree
                datateam={teamTreeData}
                selectTeam={(Team) => selectteam(Team)}
                isReload={isReload && isReload}
                initialTeamIds={
                  teamUserPin && pinnedTeamParentIds ? pinnedTeamParentIds : []
                }
                selectedTeamIds={selectedTeamIds}
                selectTeamParent={selectTeamParent}
                allTeamSelect={allTeamSelect}
                setAllTeamSelect={setAllTeamSelect}
              />
            ) : null
          ) : null}

          {usershow && (
            <UserTree
              filterDataPass={(Users) => selectUsers(Users)}
              userNodes={userNodes && userNodes}
              isReload={singleSelect && !singleSelectReload ? false : isReload}
              singleSelect={singleSelect}
              selectedUserIds={selectedUserIds}
              singleSelectReload
            />
          )}
          {((usershow && !singleSelect) || teamshow) && (
            <div className="ml-1">
              <Tooltip
                title={
                  teamUserPin
                    ? "Unpin Team & User filters"
                    : "Pin Team & User filters"
                }
              >
                {teamUserPin ? (
                  <BsPinAngleFill
                    className="w-5 h-5  p-1 cursor-pointer rounded hover:bg-gray-200 hover:text-blue-500"
                    onClick={onTeamUserPin}
                  />
                ) : (
                  <BsPinAngle
                    id="team-user-pin"
                    className="w-5 h-5 p-1 cursor-pointer rounded hover:bg-gray-200 hover:text-blue-500 transition duration-500 ease-in-out"
                    onClick={onTeamUserPin}
                  />
                )}
              </Tooltip>
            </div>
          )}
          {isAppUrl && (
            <div className="pl-5">
              <CheckboxComponent
                options={checkboxOptions}
                defaultValue={checkboxDefaultValue}
                onChange={appUrlOnChange}
              />
            </div>
          )}
          <div className="ml-3" />
          {prefixComponents}
        </div>

        <div className="f_end gap-3">
          {children}
          {monthshow ? (
            <DatePicker
              inputReadOnly
              defaultValue={datePin && pinnedDate ? pinnedDate : initialDate}
              value={date}
              disabledDate={disabledDate}
              format="YYYY-MM-DD "
              style={{ width: 180 }}
              className={isActive ? "cal_active" : null}
              onChange={onDateChange}
              clearIcon={null}
            />
          ) : null}
          {prefixTimePickerComponents}

          {rangeshow ? (
            <RangePicker
              inputReadOnly
              defaultValue={
                datePin && pinnedEndDate && pinnedStartDate
                  ? [pinnedStartDate, pinnedEndDate]
                  : [sDate, eDate]
              }
              value={[startDate, endDate]}
              presets={
                reportType === "crossday" ? rangePresetsCrossDay : rangePresets
              }
              onChange={onDateRangeChange}
              className={rangActive ? "rang_active" : null}
              disabledDate={
                reportType === "crossday" ? disableCurrent : disabledDate
              }
              dateRender={(current) => {
                const date = current.date();
                return date <= 31 ? (
                  <div className="ant-picker-cell-inner">{date}</div>
                ) : null;
              }}
              clearIcon={null}
            />
          ) : null}

          {monthpikershow ? (
            <MonthPicker
              inputReadOnly
              defaultValue={datePin && pinnedMonth ? pinnedMonth : initialMonth}
              value={month}
              // disabledDate={disableMonth}
              disabledDate={futureDateDisable ? disabledDate : disableMonth}
              format="MMM"
              style={{ width: 180 }}
              // bordered={false}
              className={isActive ? "cal_active" : null}
              onChange={onMonthChange}
              clearIcon={null}
            />
          ) : null}
          {(monthpikershow || monthshow || rangeshow) && (
            <div className="-ml-2 -mr-1">
              <Tooltip title={datePin ? "Unpin Date" : "Pin Date"}>
                {datePin ? (
                  <BsPinAngleFill
                    className="w-5 h-5  p-1 cursor-pointer rounded hover:bg-gray-200 hover:text-blue-500"
                    onClick={onDatePin}
                  />
                ) : (
                  <BsPinAngle
                    id="date-pin"
                    className="w-5 h-5 p-1 cursor-pointer rounded hover:bg-gray-200 hover:text-blue-500 transition duration-500 ease-in-out"
                    onClick={onDatePin}
                  />
                )}
              </Tooltip>
            </div>
          )}
          {/* {sortby ? <SortBy /> : null} */}
          {btnDownload ? (
            <div>
              <Tooltip
                title={
                  reportType === "logReport" && selectedUserIds?.length === 0
                    ? "You have to select any single user to download the Log Report."
                    : "Download"
                }
              >
                <Button
                  disabled={
                    reportType === "logReport" && selectedUserIds?.length === 0
                      ? true
                      : false
                  }
                  loading={loadingbtn}
                  icon={<DownloadOutlined />}
                  onClick={ExportDownload}
                />
              </Tooltip>
            </div>
          ) : null}
          {btnDownloadPdf ? (
            <div>
              <Tooltip title="Download PDF">
                <Button
                  loading={loadingBtnPdf}
                  icon={<DownloadOutlined />}
                  onClick={DownloadPdf}
                />
              </Tooltip>
            </div>
          ) : null}
          {btnReload && (
            <div>
              <Tooltip title="Refresh">
                <Button
                  type="primary"
                  ghost
                  icon={<ReloadOutlined />}
                  onClick={onRefresh}
                />
              </Tooltip>
            </div>
          )}
          {btnReload ? (
            <div
              className="text-xs  font-bold text-[#723BF8]  hover:underline cursor-pointer hover w-max whitespace-nowrap"
              onClick={onReload}
            >
              Clear Filters
            </div>
          ) : null}
          {prefixTimeCalComponents}

          {btnAlertSettings ? (
            <Tooltip title="Go to alert rules">
              <Button
                type="primary"
                ghost
                icon={<FiSettings />}
                onClick={handleAction}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
      <Divider style={{ width: "110%", margin: "10px -30px" }} />
    </div>
  );
};

export default Filter;
