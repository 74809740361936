/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Content } from "./components/tabs";
import { AuthContext } from "contexts/authContext";

import { Divider, Row, Col, Card, Drawer, Badge } from "antd";
import Profileinfo from "./components/profileinfo";
import ApplicationTopApp from "../Application/components/applicationTopApp";
import ApplicationTopUrl from "../Application/components/applicationTopUrl";
import ApplicationTopCategory from "../Application/components/applicationTopCategery";
// attendance
import Attendancetable from "./components/attendancetable";
import AttendanceChart from "./components/AttendanceChart";
import MonthlyAttendanceTrend from "./components/monthlyattendanceTrend";
import WellnessOverallScore from "./components/wellnessoverallScore";
import MonthlyWellnessTrend from "./components/monthlywellnessTrend";
import WellnessTable from "./components/wellnesstable";
import ProductivityBreakdown from "components/Productivity/components/productivityBreakdown";
import ProductivityTable from "./components/productivityTable";
import offlineValue from "../../utils/getOfflineTime";
import dayjs from "dayjs";
import OnlineTimeBreakdown from "components/Activity/components/onlinetimeBreakdown";
import ActivityTable from "./components/activitytable";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CategoryUtilisation from "../Application/components/categoryUtilisation";
import TotalAppusage from "../Application/components/totalappusases";
import TotalYUrlusage from "../Application/components/totalurlusases";
import AppUrlLogs from "./components/appurllogs";
import {
  ActivityDetailAction,
  ActivitySummaryAction,
  ApplicationDetailAction,
  ApplicationSummaryAction,
  AttendanceDetailedAction,
  AttendanceSummaryAction,
  BreakReportAction,
  ProductivityDetailAction,
  ProductivitySummaryAction,
  Wellness360DetailsAction,
  Wellness360SummaryAction,
} from "../../redux/store/action";
import { hm, onlyHoursNumber } from "utils/timeConverter";
import { useSelector, useDispatch } from "react-redux";
import GetParamers from "utils/getParamers";
import Filter from "components/Filter";
import { permissionsMap } from "constants/constant";
import InnerHeader from "common/InnerHeader";
import { AiOutlineUser } from "react-icons/ai";
import { analyticsPageEvent } from "utils/analyticsUtils";
import {
  ACTIVITY,
  APPLICATION,
  ATTENDANCE,
  PRODUCTIVITY,
  WELLNESS,
  REPORTS,
} from "route/constant";
import { closeUserDetail } from "redux/store/user-detail/action";
import BreakTable from "./components/breaktable";
import BreakTrends from "components/Attendance/components/breakTrends";
import _ from "lodash";
import UserInfoDetail from "./components/Scorecard/userInfoDetail";
import UserCompairsonTeamWiseRadar from "./components/Scorecard/UserCompairsonTeamWiseRadar";
import UserCompairsonCompanyTeamWiseTable from "./components/Scorecard/UserCompairsonCompanyTeamWiseTable";
import UserCompairsonCompanyWiseRadar from "./components/Scorecard/UserCompairsonCompanyWiseRadar";
import UserMonthlyAttendance from "./components/Scorecard/UserMonthlyAttendance";
import UserMonthlyGoal from "./components/Scorecard/UserMonthlyGoal";
import UserActivityTrend from "./components/Scorecard/UserActivityTrend";
import UserProducitivtyTrend from "./components/Scorecard/UserProducitivtyTrend";
import {
  comparativeAnalysisUserPerformanceProcess,
  comparativeAttendanceUserPerformanceProcess,
  comparativeGoalUserPerformanceProcess,
  comparativeApplicationUserPerformanceProcess,
} from "redux/store/user-scorecard/action";
import UserApplication from "./components/Scorecard/UserApplication";
import UserWellness from "./components/Scorecard/UserWellness";
import { attendance } from "constants/timeCalConstant";
import { handleDownloadPdf } from "utils/downloadPdf";
import { BreaksReportProcess } from "redux/store/reports/break-report/action";
import CompanyLogo from "../../assets/images/logo.png";

dayjs.extend(customParseFormat);

const UserDetail = (props) => {
  const {
    open,
    sDate = dayjs().subtract(6, "d"),
    eDate = dayjs(),
    tab = 0,
    identityId,
    page,
  } = props;

  const [dateDiff, setDateDiff] = useState(1);
  const [isMonthFilter, setIsMonthFilter] = useState(false);
  const users = useSelector((state) => state.users);

  const [active, setActive] = useState(tab && tab);
  const [startDate, setstartDate] = useState(sDate);
  const [endDate, setendDate] = useState(eDate);
  const [month, setMonth] = useState(dayjs().month() + 1);
  const [userIds, setuserIds] = useState([
    identityId ||
      (users?.medetails_data?.identity?.non_tracking &&
      users?.user_list_data?.length
        ? users.user_list_data[0]?.identity.id
        : users.medetails_data?.identity?.id),
  ]);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [groupIds, setgroupIds] = useState([]);

  const dispatch = useDispatch();
  const {
    KState,
    checkRequiredPermissions,

    checkRequiredLicense,
    getDefaultHeader,
  } = useContext(AuthContext);

  const applicationsummary = useSelector((state) => state.applicationsummary);
  const productivitysummary = useSelector((state) => state.productivitysummary);
  const productivitydetail = useSelector((state) => state.productivitydetail);
  const userscorecard = useSelector((state) => state.userscorecard);
  const wellness360summary = useSelector((state) => state.wellness360summary);
  const { team_list_data } = useSelector((state) => state.team);

  const [topAppData, settopAppData] = useState([]);
  const [topUrlData, settopUrlData] = useState([]);
  const [topCategoryData, settopCategoryData] = useState([]);
  const [ApplicationAppWorkingHoursTime, setApplicationAppWorkingHoursTime] =
    useState([]);

  const [ApplicationAppCategoryList, setApplicationAppCategoryList] = useState(
    []
  );
  const [ApplicationUrlWorkingHoursTime, setApplicationUrlWorkingHoursTime] =
    useState([]);

  const [ApplicationUrlCategoryList, setApplicationUrlCategoryList] = useState(
    []
  );

  const [breakDownSummary, setbreakDownSummary] = useState([]);

  const activitydetails = useSelector((state) => state.actvitydetail);

  const [
    ApplicationCategoryWorkingHoursTime,
    setApplicationCategoryWorkingHoursTime,
  ] = useState([]);

  const [ApplicationCategoryList, setApplicationCategoryList] = useState([]);
  const activitysummary = useSelector((state) => state.activitysummary);
  const [employeeListData, setemployeeListData] = useState([]);

  const [employeeListDataProductivity, setemployeeListDataProductivity] =
    useState([]);
  const [workingTimeTrendData, setworkingTimeTrendData] = useState([]);
  const [teamWiseActivityTrendData, setTeamWiseActivityTrendData] = useState(
    []
  );
  const [teamWiseProductivityTrendData, setTeamWiseProductivityTrendData] =
    useState([]);
  const [userPerformance, setUserPerformance] = useState([]);
  const [teamPerformance, setTeamPerformance] = useState([]);
  const [userComparativeDurationPerformance, setUserDurationPerformance] =
    useState([]);
  const [teamComparativeDurationPerformance, setTeamDurationPerformance] =
    useState([]);

  const [companyPerformance, setCompanyPerformance] = useState([]);
  const [userTeamCompanyPerformance, setUserTeamCompanyPerformance] = useState(
    []
  );
  const [companyComparativeDurationPerformance, setCompanyDurationPerformance] =
    useState([]);

  const [monthSelected, setMonthSelected] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );
  const [wellnessCount, setWellnessCount] = useState([]);
  const [wellnessAllCount, setWellnessAllCount] = useState([]);
  const [ShowDateInDownload, setShowDateInDownload] = useState(false);
  const [thumbLoading, setThumbLoading] = useState(true);

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    let sDate = {};
    let eDate = {};
    if (index === 1) {
      setIsMonthFilter(true);
    } else if (index === 8) {
      setIsMonthFilter(true);
      const previousMonth = dayjs().subtract(1, "month");
      const previousMonthStart = dayjs().subtract(1, "month").startOf("month");

      sDate = previousMonth.startOf("month");
      eDate = previousMonth.endOf("month");
      setstartDate(sDate);
      setendDate(eDate);

      setMonthSelected(previousMonthStart);
    } else {
      setIsMonthFilter(false);
      sDate = startDate;
      eDate = endDate;
      setMonthSelected(startDate);

      // sDate = dayjs().subtract(6, "day");
      // eDate = dayjs();
      setstartDate(sDate);
      setendDate(eDate);
    }

    if (index !== active) {
      if (index !== 1 && active !== 1) {
        loadAPi(index, sDate, eDate, userIds);
      }
      setActive(index);
    }
  };

  const loadAPi = (active, sDate, eDate, userIds) => {
    if (KState && KState.keycloak && users) {
      if (active === 0) {
        AttendanceTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else if (active === 1) {
        setIsMonthFilter(true);
        WellnessTab(KState.keycloak, sDate, eDate, groupIds, userIds, 1, 0);
      } else if (active === 2) {
        ProductivityTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else if (active === 3) {
        ActivityTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else if (active === 4) {
        ApplicationTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else if (active === 7) {
        BreakTab(KState.keycloak, sDate, eDate, groupIds, userIds, 1, 15);
      } else if (active === 8) {
        setIsMonthFilter(true);
        // sDate =
        //   dayjs(sDate).startOf("month").format("YYYY-MM-DD") +
        //   "T00:00:00.474505";
        // eDate =
        //   dayjs(eDate).endOf("month").format("YYYY-MM-DD") + "T23:59:59.474505";
        ScorecardTab(KState.keycloak, sDate, eDate, groupIds, userIds, 1, 0);
      } else {
        AttendanceTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      }
    }
  };

  const BreakTab = (
    keycloak,
    startDate,
    endDate,
    groupIds,
    userIds,
    page,
    limit
  ) => {
    dispatch(
      BreaksReportProcess({
        body: {
          start_date: startDate.format("YYYY-MM-DD") + "T00:00:00.474505",
          end_date: endDate.format("YYYY-MM-DD") + "T23:59:59.474505",
          sort_by: "break_start",
          desc: true,
          //   type: "pc",
          page: page,
          limit: limit,
          user_id: userIds?.length > 0 ? userIds.join(",") : "",
          group_id: groupIds?.length > 0 ? groupIds.join(",") : "",
        },

        header: getDefaultHeader(),
        // isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
      })
    );

    dispatch(
      AttendanceSummaryAction.AttendanceSummaryBreakTrendDateWiseProcess(
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 0, true)
      )
    );
  };

  const ScorecardTab = (
    keycloak,
    startDate,
    endDate,
    groupIds,
    userIds,
    page,
    limit
  ) => {
    dispatch(
      comparativeAnalysisUserPerformanceProcess({
        body: {
          start_date:
            dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00.474505",
          end_date: dayjs(endDate).format("YYYY-MM-DD") + "T23:59:59.474505",
          user_id: userIds[0],
        },

        header: getDefaultHeader(),
      })
    );
    dispatch(
      comparativeAttendanceUserPerformanceProcess({
        body: {
          start_date:
            dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00.474505",
          end_date: dayjs(endDate).format("YYYY-MM-DD") + "T23:59:59.474505",
          user_id: userIds[0],
        },

        header: getDefaultHeader(),
      })
    );
    dispatch(
      comparativeGoalUserPerformanceProcess({
        body: {
          start_date:
            dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00.474505",
          end_date: dayjs(endDate).format("YYYY-MM-DD") + "T23:59:59.474505",
          user_id: userIds[0],
        },

        header: getDefaultHeader(),
      })
    );
    dispatch(
      comparativeApplicationUserPerformanceProcess({
        body: {
          start_date:
            dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00.474505",
          end_date: dayjs(endDate).format("YYYY-MM-DD") + "T23:59:59.474505",
          user_id: userIds[0],
        },

        header: getDefaultHeader(),
      })
    );

    dispatch(
      ActivityDetailAction.ActivityDetailedWorkingTimeTrendProcess(
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, page, 0, true)
      )
    );
    dispatch(
      ProductivityDetailAction.ProductivityDetailedTeamWisProductivityTrendProcess(
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          page,
          limit,
          true,
          false
        )
      )
    );
    dispatch(
      ActivityDetailAction.ActivityEmployeeDetailedEmployeeListProcess(
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );
    dispatch(
      ProductivityDetailAction.ProductivityEmployeeDetailedEmployeeListProcess(
        // EmpListDataProductiviy
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );
    dispatch(
      Wellness360SummaryAction.Wellness360MeOverallScoreProcess(
        // preRequest
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );
  };
  // const loadAPi = (active) => {
  //   if (KState && KState.keycloak && users) {
  //     // setChangeSegmented(true);
  //     // ClearSummaryTab();
  //     // ClearDetailsTab();
  //     if (active === 0) {
  //       AttendanceTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     } else if (active === 1) {
  //       WellnessTab(
  //         KState.keycloak,
  //         startDate,
  //         endDate,
  //         groupIds,
  //         userIds,
  //         1,
  //         0
  //       );
  //     } else if (active === 2) {
  //       ProductivityTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     } else if (active === 3) {
  //       ActivityTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     } else if (active === 4) {
  //       ApplicationTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     }
  //   }
  // };

  useEffect(() => {
    analyticsPageEvent("UserDetail");
  }, []);

  const AttendanceTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    dispatch(
      AttendanceDetailedAction.AttendanceDetailedAttendanceTrendProcess(
        // datacurrent1
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          true
        )
      )
    );
  };

  const WellnessTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = "",
    page,
    limit
  ) => {
    dispatch(
      Wellness360SummaryAction.Wellness360MeOverallScoreProcess(
        // preRequest
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      Wellness360DetailsAction.Wellness360EmployeeDetailsEmployeeListProcess(
        // PreData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, page, limit)
      )
    );
  };

  const ProductivityTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    calculateDifference(startDate, endDate);

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTopAppUrlProcess(
        // topAppUrlData
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "productivity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryTopCategoryProcess(
        // topCategoryDataset
        keycloak.token,
        // GetParamers(startDate, endDate, groupIds, userIds)
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "productivity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryWorkingTimeProcess(
        // WorkingTimeData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryProductivityBreakdownProcess(
        // ProductivityBreackDownData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ProductivityDetailAction.ProductivityEmployeeDetailedEmployeeListProcess(
        // EmpListDataProductiviy
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );
  };

  const ActivityTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    calculateDifference(startDate, endDate);

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTopAppUrlProcess(
        // topAppUrlData
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryTopCategoryProcess(
        // topCategoryDataset
        keycloak.token,
        // GetParamers(startDate, endDate, groupIds, userIds)
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ActivitySummaryAction.ActivitySummaryOnlineTimeBreakdownProcess(
        // OnlineTimeBreackDownData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 0, false, true)
      )
    );

    dispatch(
      ActivitySummaryAction.ActivitySummaryActivityLevelBreakdownProcess(
        // levelBreakDownData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ActivityDetailAction.ActivityEmployeeDetailedEmployeeListProcess(
        // EmpListData

        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );
  };

  const ApplicationTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTopAppUrlProcess(
        // topAppUrlData
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryTopCategoryProcess(
        // topCategoryDataset
        keycloak.token,
        // GetParamers(startDate, endDate, groupIds, userIds)
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTotalApplicationUsageProcess(
        // totalApplicationUsageData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTotalUrlUsageProcess(
        // totalUrlUsageData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryCategoryUtilizationProcess(
        // CategoryUtilizationData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ApplicationDetailAction.ApplicationDetailedSearchWiseAppDetailProcess(
        // SearchWiseAppDetaildata
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "app",
          "",
          false,
          "",
          "",
          "",
          "",
          false,
          "",
          true
        )
      )
    );

    dispatch(
      ApplicationDetailAction.ApplicationDetailedSearchWiseUrlDetailProcess(
        // SearchWiseUrlDetaildata
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "url",
          false,
          "",
          "",
          "",
          "",
          false,
          "",
          true
        )
      )
    );

    dispatch(
      ApplicationDetailAction.ApplicationDetailedSearchWiseAppUrlDetailProcess(
        // SearchWiseAppUrlDetaildata
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "app",
          "url"
        )
      )
    );
  };

  useEffect(() => {
    if (applicationsummary.application_summary_app_url_success === true) {
      const AppData = [];
      if (_.size(applicationsummary.application_summary_app_url_data) > 0) {
        AppData.push({
          id: "1",
          label: "Top Application",
          value:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_application_uses === null
              ? "--"
              : applicationsummary.application_summary_app_url_data
                  .top_application_uses.application_name,
          bottomtext:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_application_uses === null
              ? "--"
              : hm(
                  applicationsummary.application_summary_app_url_data
                    .top_application_uses.total_duration
                ),
          infotitle:
            "The most used application of the filtered team/organisation",
          data:
            applicationsummary &&
            applicationsummary.application_summary_app_url_data,
        });
      } else {
        AppData.push({
          id: "1",
          label: "Top Application",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used application of the filtered team/organisation",
          data: null,
        });
      }
      settopAppData(AppData);
    }

    if (applicationsummary.application_summary_app_url_success === true) {
      const ulrdata = [];
      if (_.size(applicationsummary.application_summary_app_url_data) > 0) {
        ulrdata.push({
          id: "2",
          label: "Top URL",
          value:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_urls_uses === null
              ? "--"
              : applicationsummary.application_summary_app_url_data
                  .top_urls_uses.url,
          bottomtext:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_urls_uses === null
              ? "--"
              : hm(
                  applicationsummary.application_summary_app_url_data
                    .top_urls_uses.total_duration
                ),
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data:
            applicationsummary &&
            applicationsummary.application_summary_app_url_data,
        });
        settopUrlData(ulrdata);
      } else {
        ulrdata.push({
          id: "2",
          label: "Top URL",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data: null,
        });
      }
    }

    if (
      productivitysummary.productivity_summary_top_category_success === true
    ) {
      const categorydata = [];
      if (
        _.size(productivitysummary.productivity_summary_top_category_data) > 0
      ) {
        categorydata.push({
          id: "3",
          label: "Top Category",
          value:
            productivitysummary.productivity_summary_top_category_data &&
            productivitysummary.productivity_summary_top_category_data
              .category === null
              ? "--"
              : productivitysummary.productivity_summary_top_category_data
                  .category,
          bottomtext:
            productivitysummary.productivity_summary_top_category_data &&
            productivitysummary.productivity_summary_top_category_data
              .duration === null
              ? "--"
              : hm(
                  productivitysummary.productivity_summary_top_category_data
                    .duration
                ),
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data:
            productivitysummary &&
            productivitysummary.productivity_summary_top_category_data,
        });
      } else {
        categorydata.push({
          id: "3",
          label: "Top Category",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data: null,
        });
      }

      settopCategoryData(categorydata);
    }
    if (
      applicationsummary.application_summary_total_application_usage_success ===
      true
    ) {
      const onlyApplicationAppPer = [];
      const onlyApplicationAppDur = [];
      const onlyApplicationTeamList = [];
      if (
        _.size(
          applicationsummary &&
            applicationsummary.application_summary_total_application_usage_data
        ) > 0
      ) {
        const AppTotal = _.orderBy(
          applicationsummary.application_summary_total_application_usage_data,
          "productive_duration",
          "desc"
        );

        const AppTen = _.take(AppTotal, 10);

        AppTen.map((item) => {
          onlyApplicationAppPer.push(item.prod_percent);
          onlyApplicationAppDur.push(item.productive_duration);
          onlyApplicationTeamList.push(item.application_name);
        });
        setApplicationAppWorkingHoursTime(onlyApplicationAppDur);
        setApplicationAppCategoryList(onlyApplicationTeamList);
      }
    }
    if (
      applicationsummary.application_summary_total_url_usage_success === true
    ) {
      const onlyApplicationUrlPer = [];
      const onlyApplicationUrlDur = [];
      const onlyApplicationUrlList = [];
      if (
        _.size(
          applicationsummary &&
            applicationsummary.application_summary_total_url_usage_data
        ) > 0
      ) {
        const UrlTotal = _.orderBy(
          applicationsummary.application_summary_total_url_usage_data,
          "productive_duration",
          "desc"
        );

        const urlTen = _.take(UrlTotal, 10);

        urlTen.map((item) => {
          onlyApplicationUrlPer.push(item.prod_percent);
          onlyApplicationUrlDur.push(item.productive_duration);
          onlyApplicationUrlList.push(item.url);
        });
        setApplicationUrlWorkingHoursTime(onlyApplicationUrlDur);
        setApplicationUrlCategoryList(onlyApplicationUrlList);
      }
    }
    if (
      applicationsummary.application_summary_category_utilization_success ===
      true
    ) {
      const onlyApplicationCategoryUtilizationPer = [];
      const onlyApplicationCategoryUtilizationDur = [];
      const onlyApplicationCategoryUtilizationList = [];
      if (
        (
          applicationsummary?.application_summary_category_utilization_data ||
          []
        ).length > 0
      ) {
        applicationsummary.application_summary_category_utilization_data.map(
          (item) => {
            onlyApplicationCategoryUtilizationPer.push(
              item.percentage_duration
            );
            onlyApplicationCategoryUtilizationDur.push(item.active_duration);
            onlyApplicationCategoryUtilizationList.push(
              !item.cate_name ? "null" : item.cate_name
            );
          }
        );

        setApplicationCategoryWorkingHoursTime(
          onlyApplicationCategoryUtilizationDur
        );
        setApplicationCategoryList(onlyApplicationCategoryUtilizationList);
      }
    }

    if (
      activitysummary.activity_summary_activity_level_break_down_success ===
      true
    ) {
      const activityLevelData = [];
      if (
        _.size(
          activitysummary &&
            activitysummary.activity_summary_activity_level_break_down_data
        ) > 0
      ) {
        activityLevelData.push({
          series: [
            activitysummary &&
              activitysummary.activity_summary_activity_level_break_down_data
                .greater_then_75_count,
            activitysummary.activity_summary_activity_level_break_down_data
              .less_then_75_equal_50_count,
            activitysummary.activity_summary_activity_level_break_down_data
              .less_then_50_count,
          ],
        });
      }
    }

    if (
      activitydetails.activity_employee_detailed_employee_list_success === true
    ) {
      const empData = [];
      if (
        _.size(activitydetails.activity_employee_detailed_employee_list_data) >
        0
      ) {
        activitydetails.activity_employee_detailed_employee_list_data.map(
          (item) => {
            empData.push({
              id: item.identity_id,
              employee: item.first_name + " " + item.last_name,
              attendance: item?.present,
              totalworkingtime: item.punch_duration,
              totalonlinetime: item.online_duration,
              totalofflinetime: offlineValue(
                item.punch_duration,
                item.online_duration,
                item.break_duration
              ),
              totalactivetime: item.active_duration,
              totalidletime: item.idle_duration,
              totalbreaktime: item.break_duration,
              activityper: item.active_percent,
              attendance: item?.present,
              key_presses: item?.key_presses,
              mouse_clicks: item?.mouse_clicks,
            });
          }
        );
      }
      setemployeeListData(empData);
    }

    if (
      productivitysummary.productivity_summary_working_time_success === true
    ) {
      const workingTimeSum = [];
      if (
        _.size(productivitysummary.productivity_summary_working_time_data) > 0
      ) {
        productivitysummary.productivity_summary_working_time_data.map(
          (item) => {
            workingTimeSum.push([
              item.total_online_duration === null
                ? 0
                : item.total_online_duration,
              offlineValue(
                item.total_punch_duration,
                item.total_online_duration
              ),
              item.total_break_duration === null
                ? 0
                : item.total_break_duration,
            ]);
          }
        );
      }
    }
    if (productivitysummary.productivity_summary_working_time_failed === true) {
      // message.error(
      //   productivitysummary.productivity_summary_working_time_message.message
      // );
    }
    if (
      productivitysummary.productivity_summary_productivity_break_down_success ===
      true
    ) {
      const breakdownSum = [];
      const PercentData = [];
      if (
        _.size(
          productivitysummary.productivity_summary_productivity_break_down_data
        ) > 0
      ) {
        productivitysummary.productivity_summary_productivity_break_down_data.map(
          (item) => {
            breakdownSum.push([
              item.total_productive_duration === null
                ? 0
                : item.total_productive_duration,
              item.total_unproductive_duration === null
                ? 0
                : item.total_unproductive_duration,
              item.total_neutral_duration === null
                ? 0
                : item.total_neutral_duration,
            ]);
            PercentData.push({
              id: "2",
              label: "Product Percentage",
              value: !item.total_productive_duration
                ? 0
                : parseFloat(
                    (item.total_productive_duration /
                      (item.total_productive_duration +
                        (item.total_unproductive_duration || 0) +
                        (item.total_neutral_duration || 0))) *
                      100
                  ).toFixed(2),

              infotitle:
                "The most used application of the filtered team/organisation",
              data: item,
            });
          }
        );
      } else {
        PercentData.push({
          id: "2",
          label: "Product Percentage",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used application of the filtered team/organisation",
          data: null,
        });
      }

      setbreakDownSummary(breakdownSum[0]);
    }
    if (
      productivitysummary.productivity_summary_productivity_break_down_failed ===
      true
    ) {
      // message.error(
      //   productivitysummary.productivity_summary_productivity_break_down_message
      //     .message
      // );
    }
    if (
      activitydetails?.activity_detailed_working_time_trend_success === true
    ) {
      const workingTime = [];
      const onlineTime = [];
      const breakTime = [];
      const OfflineTime = [];
      const activityTime = [];
      const idleTime = [];
      const formattedDate = [];
      const date = [];

      if (
        _.size(activitydetails.activity_detailed_working_time_trend_data) > 0
      ) {
        activitydetails?.activity_detailed_working_time_trend_data.forEach(
          (item) => {
            workingTime.push(
              !item.punch_duration ? 0 : parseInt(item.punch_duration)
            );

            onlineTime.push(
              !item.online_duration ? 0 : parseInt(item.online_duration)
            );

            breakTime.push(
              !item.break_duration ? 0 : parseInt(item.break_duration)
            );

            OfflineTime.push(
              !(item.punch_duration && item.online_duration)
                ? 0
                : offlineValue(
                    item.punch_duration,
                    item.online_duration,
                    item.break_duration
                  )
            );

            activityTime.push(!item.active_duration ? 0 : item.active_duration);
            idleTime.push(!item.idle_duration ? 0 : item.idle_duration);
            formattedDate.push(
              dayjs(item.start_timing + "T00:00:00.474505").format("DD/MM")
            );
            date.push(dayjs(item.start_timing));
          }
        );
      }

      setworkingTimeTrendData({
        workingTime: workingTime,
        onlineTime: onlineTime,
        breakTime: breakTime,
        offlineTime: OfflineTime,
        formattedDate: formattedDate,
        date: date,
      });

      setTeamWiseActivityTrendData({
        activityTime: activityTime,
        idleTime: idleTime,
        formattedDate: formattedDate,
        date: date,
      });
    }
    if (
      productivitydetail.productivity_detailed_team_wise_productivity_trend_success ===
      true
    ) {
      const productiveTime = [];
      const unproductiveTime = [];
      const neutralTime = [];
      const formattedDate = [];
      const date = [];
      if (
        _.size(
          productivitydetail.productivity_detailed_team_wise_productivity_trend_data
        ) > 0
      ) {
        productivitydetail.productivity_detailed_team_wise_productivity_trend_data.map(
          (item) => {
            if (item.date !== null) {
              formattedDate.push(dayjs(item.date).format("DD/MM"));
              date.push(dayjs(item.date));
              productiveTime.push(
                !item.productive_duration ? 0 : item.productive_duration
              );
              unproductiveTime.push(
                !item.unproductive_duration ? 0 : item.unproductive_duration
              );
              neutralTime.push(
                !item.neutral_duration ? 0 : item.neutral_duration
              );
            }
          }
        );
      }

      setTeamWiseProductivityTrendData({
        productiveTime: productiveTime,
        unproductiveTime: unproductiveTime,
        neutralTime: neutralTime,

        formattedDate: formattedDate,
        date: date,
      });
    }

    if (userscorecard.comparative_analysis_user_performance_success === true) {
      const userComparativePerformance = [];
      const teamComparativePerformance = [];
      const userComparativeDurationPerformance = [];
      const teamComparativeDurationPerformance = [];
      const companyComparativePerformance = [];
      const companyComparativeDurationPerformance = [];

      if (userscorecard.comparative_analysis_user_performance_data !== null) {
        userComparativePerformance.push(
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[0]
              .user_wise.average_punch_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[0]
              .user_wise.average_active_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[0]
              .user_wise.average_online_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[0]
              .user_wise.average_productivity_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[0]
              .user_wise.average_idle_duration
          )
        );
        teamComparativePerformance.push(
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[1]
              .group_wise.average_punch_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[1]
              .group_wise.average_active_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[1]
              .group_wise.average_online_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[1]
              .group_wise.average_productivity_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[1]
              .group_wise.average_idle_duration
          )
        );
        userComparativeDurationPerformance.push(
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[0].user_wise
            .average_punch_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[0].user_wise
            .average_active_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[0].user_wise
            .average_online_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[0].user_wise
            .average_productivity_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[0].user_wise
            .average_idle_duration
        );
        teamComparativeDurationPerformance.push(
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[1].group_wise
            .average_punch_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[1].group_wise
            .average_active_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[1].group_wise
            .average_online_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[1].group_wise
            .average_productivity_duration,
          // onlyHoursNumber(
          userscorecard.comparative_analysis_user_performance_data[1].group_wise
            .average_idle_duration
          // )
        );
        companyComparativePerformance.push(
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[2]
              .company_wise.average_punch_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[2]
              .company_wise.average_active_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[2]
              .company_wise.average_online_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[2]
              .company_wise.average_productivity_duration
          ),
          onlyHoursNumber(
            userscorecard.comparative_analysis_user_performance_data[2]
              .company_wise.average_idle_duration
          )
        );
        companyComparativeDurationPerformance.push(
          userscorecard.comparative_analysis_user_performance_data[2]
            .company_wise.average_punch_duration,
          userscorecard.comparative_analysis_user_performance_data[2]
            .company_wise.average_active_duration,
          userscorecard.comparative_analysis_user_performance_data[2]
            .company_wise.average_online_duration,
          userscorecard.comparative_analysis_user_performance_data[2]
            .company_wise.average_productivity_duration,
          userscorecard.comparative_analysis_user_performance_data[2]
            .company_wise.average_idle_duration
        );
        setUserPerformance(userComparativePerformance);
        setTeamPerformance(teamComparativePerformance);
        setUserDurationPerformance(userComparativeDurationPerformance);
        setTeamDurationPerformance(teamComparativeDurationPerformance);
        setCompanyPerformance(companyComparativePerformance);
        setCompanyDurationPerformance(companyComparativeDurationPerformance);

        setUserTeamCompanyPerformance(
          userscorecard.comparative_analysis_user_performance_data
        );
      } else {
        setUserPerformance([]);
        setTeamPerformance([]);
        setCompanyPerformance([]);
        setUserTeamCompanyPerformance([]);
        setCompanyDurationPerformance([]);
        setUserDurationPerformance([]);
        setTeamDurationPerformance([]);
      }
    }
    if (wellness360summary.wellness360_me_overall_score_success === true) {
      if (
        Object.keys(wellness360summary.wellness360_me_overall_score_data)
          .length > 0
      ) {
        const wellnessCountData = [];
        const wellnessAllCountData = [];

        // wellness360summary.wellness360_me_overall_score_data.map((item) => {
        wellnessAllCountData.push([
          wellness360summary.wellness360_me_overall_score_data
            .healthy_emp_count,
          wellness360summary.wellness360_me_overall_score_data
            .overburdened_emp_count,
          wellness360summary.wellness360_me_overall_score_data
            .underutilized_emp_count,
          wellness360summary.wellness360_me_overall_score_data.healthy_emp_per,
          wellness360summary.wellness360_me_overall_score_data
            .overburdened_emp_per,
          wellness360summary.wellness360_me_overall_score_data
            .underutilized_emp_per,
        ]);
        wellnessCountData.push([
          wellness360summary.wellness360_me_overall_score_data
            .healthy_emp_count,
          wellness360summary.wellness360_me_overall_score_data
            .overburdened_emp_count,
          wellness360summary.wellness360_me_overall_score_data
            .underutilized_emp_count,
        ]);
        // });
        setWellnessCount(wellnessCountData[0]);
        setWellnessAllCount(wellnessAllCountData[0]);
      }
    }
    if (
      productivitydetail.productivity_employee_detailed_employee_list_success ===
      true
    ) {
      const empData = [];
      if (
        _.size(
          productivitydetail.productivity_employee_detailed_employee_list_data
        ) > 0
      ) {
        productivitydetail.productivity_employee_detailed_employee_list_data.map(
          (item) => {
            empData.push({
              id: item.identity_id,
              employee: item.first_name + " " + item.last_name,
              attendance: item?.present,
              totalworkingtime: item.punch_duration,
              totalonlinetime: item.online_duration,
              totalofflinetime: offlineValue(
                item.punch_duration,
                item.online_duration,
                item.break_duration
              ),
              totalproductivetime: item.productive_duration,
              totalunproductivetime: item.unproductive_duration,
              totalneutraltime: item.neutral_duration,
              totalbreaktime: item.break_duration,
              activityper: item.productivity_percentage,
              attendance: item?.present,
              key_presses: item?.key_presses,
              mouse_clicks: item?.mouse_clicks,
            });
          }
        );
      }
      setemployeeListDataProductivity(empData);
    } else if (
      productivitydetail.productivity_employee_detailed_employee_list_failed ===
      true
    ) {
      // message.error(
      //   productivitydetail.productivity_employee_detailed_employee_list_message
      //     .message
      // );
    }
  }, [applicationsummary, activitydetails, productivitydetail, userscorecard]);

  const calculateDifference = (start, end) => {
    const date1 = dayjs(start);
    const date2 = dayjs(end);
    const difference = date2.diff(date1, "days");
    setDateDiff(difference);
  };
  // const pagination = (page, limit) => {
  //   const PreData = {
  //     clientId: KState.keycloak.clientId,
  //     token: KState.keycloak.token,
  //     schemaname: KState.keycloak.clientId,
  //     identityid:
  //       users.medetails_data &&
  //       users.medetails_data.identity &&
  //       users.medetails_data.identity.id,
  //     start_date: startDate + "T00:00:00.474505",
  //     end_date: endDate + "T23:59:59.474505",
  //     group_id: _.size(groupIds) > 0 ? groupIds.join(",") : "",
  //     user_id: _.size(userIds) > 0 ? userIds.join(",") : "",
  //     page: page,
  //     limit: limit,
  //   };

  //   dispatch(
  //     Wellness360DetailsAction.Wellness360DetailsEmployeeListProcess(PreData)
  //   );
  // };

  const onCloseModal = () => {
    dispatch(closeUserDetail());
  };

  const updateAllFilters = ({
    filterUserIds = userIds,
    filterStartDate = startDate,
    filterEndDate = endDate,
    filterMonthStart = startDate,
    filterMonthEnd = endDate,
    datePin,
    initialized,
  }) => {
    if (initialized) {
      if (datePin) {
        if (isMonthFilter) {
          setstartDate(filterMonthStart);
          setendDate(filterMonthEnd);

          loadAPi(active, filterMonthStart, filterMonthEnd, userIds);
        } else {
          setstartDate(filterStartDate);
          setendDate(filterEndDate);
          loadAPi(active, filterStartDate, filterEndDate, userIds);
        }
      } else {
        setstartDate(props?.sDate || filterStartDate);
        setendDate(props?.eDate || filterEndDate);
        loadAPi(
          active,
          props?.sDate || filterStartDate,
          props?.eDate || filterEndDate,
          userIds
        );
      }
      return;
    }

    setuserIds(filterUserIds);
    if (isMonthFilter) {
      setstartDate(filterMonthStart);
      setendDate(filterMonthEnd);
      const selectedMonthStart = dayjs(filterMonthStart).startOf("month");
      setMonthSelected(selectedMonthStart);
      loadAPi(active, filterMonthStart, filterMonthEnd, filterUserIds);
    } else {
      setMonthSelected(filterStartDate);

      setstartDate(filterStartDate);
      setendDate(filterEndDate);
      loadAPi(active, filterStartDate, filterEndDate, filterUserIds);
    }
  };

  const printRef = useRef();

  const getDownloadPdf = async () => {
    const element = printRef.current;
    const fileName = "Employee Scorecard";
    await handleDownloadPdf(
      [users && users?.medetails_data?.attached_group?.id],
      team_list_data,
      element,
      startDate,
      endDate,
      fileName
    );
    setThumbLoading(true);
    setShowDateInDownload(false);
  };
  const onShowDate = () => {
    setShowDateInDownload(true);
  };
  useEffect(() => {
    if (!thumbLoading) {
      getDownloadPdf();
    }
  }, [thumbLoading]);

  const getHOCWrapped = (children) => {
    if (page) {
      return (
        <div className="inner_layout">
          <InnerHeader
            headingicon={<AiOutlineUser size={20} />}
            headingname={
              !checkRequiredPermissions([
                permissionsMap.TENANT_OWNER,
                permissionsMap.TENANT_MANAGER,
              ])
                ? "Me"
                : "Userwise Detail"
            }
            iconbgColor={"#14B8A61a"}
            iconcolor={"#14B8A6"}
            // FirstswicthName={"Activity"}
            // SecondswicthName={"Productivity"}
            // check={activityswitch}
            // FirstClickswicth={setActivitySwitchTrue}
            // SecondClickswicth={setActivitySwitchFalse}
            segmentArray={[]}
          />{" "}
          <br />
          {children}
        </div>
      );
    }
    return (
      <Drawer
        //   title="Drawer with extra actions"
        width={"calc(100vw - 220px)"}
        className={"userdetail_block"}
        onClose={onCloseModal}
        open={open}
      >
        {children}
      </Drawer>
    );
  };

  return getHOCWrapped(
    <>
      <div className="filter_all" style={{ marginTop: "-5px" }}>
        {active === 6 ? (
          <Filter
            teamshow={false}
            usershow={false}
            monthshow={true}
            rangeshow={false}
            btnDownload={false}
            btnReload={true}
            isfilter={false}
            startDate={dayjs()}
          />
        ) : (
          <Filter
            teamshow={false}
            usershow={checkRequiredPermissions([
              permissionsMap.TENANT_OWNER,
              permissionsMap.TENANT_MANAGER,
            ])}
            futureDateDisable={active === 8 ? false : true}
            monthshow={false}
            rangeshow={!isMonthFilter}
            monthpikershow={isMonthFilter}
            btnDownload={false}
            btnReload={true}
            isfilter={false}
            startDate={startDate}
            endDate={endDate}
            month={startDate}
            singleSelect
            selectedUserIds={userIds}
            updateAllFilters={updateAllFilters}
            btnDownloadPdf={active === 8 ? true : false}
            DownloadPdf={() => {
              onShowDate();
            }}
            loadingBtnPdf={ShowDateInDownload}
          />
        )}
      </div>
      <div className="mgrid column_m2">
        <Card
          style={{
            height: "calc(100vh - 155px)",
            position: "sticky",
            top: "0px",
          }}
        >
          <Profileinfo
            UserDetaildata={
              users.user_map_data[userIds.length ? userIds[0] : identityId]
                ?.identity
            }
          />
          <Divider className="mt-2" />

          <Tabs className="mt-2">
            {/* <Tab onClick={handleClick} active={active === 5} id={5}>
              Dashboard
            </Tab> */}

            {checkRequiredLicense(ATTENDANCE) && (
              <Tab onClick={handleClick} active={active === 0} id={0}>
                Attendance
              </Tab>
            )}
            {checkRequiredLicense(REPORTS) && (
              <Tab onClick={handleClick} active={active === 7} id={7}>
                Breaks
              </Tab>
            )}

            {checkRequiredLicense(WELLNESS) && (
              <Tab onClick={handleClick} active={active === 1} id={1}>
                Wellness
              </Tab>
            )}
            {checkRequiredLicense(PRODUCTIVITY) && (
              <Tab onClick={handleClick} active={active === 2} id={2}>
                Productivity
              </Tab>
            )}
            {checkRequiredLicense(ACTIVITY) && (
              <Tab onClick={handleClick} active={active === 3} id={3}>
                Activity
              </Tab>
            )}
            {checkRequiredLicense(APPLICATION) && (
              <Tab
                onClick={handleClick}
                active={active === 4}
                id={4}
                style={{ margin: 0 }}
              >
                Apps & URLs
              </Tab>
            )}
            {checkRequiredLicense(ATTENDANCE) &&
              checkRequiredLicense(PRODUCTIVITY) &&
              checkRequiredLicense(ACTIVITY) && (
                <Tab
                  onClick={handleClick}
                  active={active === 8}
                  id={8}
                  style={{ margin: 0 }}
                >
                  Scorecard
                  <Badge
                    count="New"
                    color="teal"
                    offset={[5, 0]}
                    size="small"
                  />
                </Tab>
              )}

            {/* <Tab onClick={handleClick} active={active === 5} id={5}>
              Alerts
            </Tab> */}
          </Tabs>
        </Card>

        {/* attendance content */}
        <Content active={active === 0}>
          <div
            className="mgrid column_3by2"
            style={{ marginTop: "-20px", marginBottom: "20px" }}
          >
            <AttendanceChart
              title={"Attendance"}
              tooltip={"Displays attendance summary of the employee."}
            />

            <MonthlyAttendanceTrend
              title={"Monthly Attendance"}
              tooltip={
                "Displays monthly attendance of the employee. Hover over the dates for the total working time."
              }
              month={monthSelected}
            />
          </div>
          <Attendancetable
            identityId={userIds.length ? userIds[0] : identityId}
          />
        </Content>

        {/* wellness360 content */}
        <Content active={active === 1}>
          <div
            className="mgrid column_3by2"
            style={{ marginTop: "-20px", marginBottom: "20px" }}
          >
            <WellnessOverallScore
              title={"Wellness"}
              tooltip={
                "Displays the number of days the employee was healthy, underutilized and overburdened."
              }
            />

            <MonthlyWellnessTrend
              title={"Monthly Wellness"}
              tooltip={
                "Displays the monthly wellness status of the employee. Hover over the dates for daily status."
              }
              month={monthSelected}
            />
          </div>
          <div className="modal_table">
            <WellnessTable />
          </div>
        </Content>

        {/* productive Content */}
        <Content active={active === 2}>
          <div className="mgrid column_3">
            {/* {numberstat.map((nitem, nindex) => {
              return (
                <NumberStats
                  key={nindex}
                  label={nitem.label}
                  value={nitem.value}
                  infotitle={nitem.infotitle}
                  bottomtext={nitem.bottomtext}
                />
              );
            })} */}
            <ApplicationTopApp
              key={1}
              label={"Top Application"}
              value={_.first(topAppData) ? _.first(topAppData).value : "-"}
              infotitle={
                "Displays the most used app by the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topAppData) ? _.first(topAppData).bottomtext : "-"
              }
              fulldata={_.first(topAppData) ? _.first(topAppData).data : "-"}
            />

            <ApplicationTopUrl
              key={2}
              label={"Top URL"}
              value={_.first(topUrlData) ? _.first(topUrlData).value : "-"}
              infotitle={
                "The most used URL of the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topUrlData) ? _.first(topUrlData).bottomtext : "-"
              }
              fulldata={_.first(topUrlData) ? _.first(topUrlData).data : "-"}
            />

            <ApplicationTopCategory
              key={3}
              label={"Top Category"}
              value={
                _.first(topCategoryData) ? _.first(topCategoryData).value : "-"
              }
              infotitle={
                "Displays the most used category of apps/URLs by the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topCategoryData)
                  ? _.first(topCategoryData).bottomtext
                  : "-"
              }
              fulldata={
                _.first(topCategoryData) ? _.first(topCategoryData).data : "-"
              }
            />
          </div>
          <div
            className="mgrid"
            style={{ marginTop: "0px", marginBottom: "20px" }}
          >
            <ProductivityBreakdown
              breakDownSummary={breakDownSummary && breakDownSummary}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            {/* <WorkingTimeTrends /> */}
            {/* <TeamwiseProductivityTrends /> */}
          </div>

          <div className="modal_table">
            <ProductivityTable
              employeeListData={
                employeeListDataProductivity && employeeListDataProductivity
              }
              duration={dateDiff}
            />
          </div>
        </Content>

        {/* activity content */}
        <Content active={active === 3}>
          <div className="mgrid column_3">
            <>
              <ApplicationTopApp
                key={1}
                label={"Top Application"}
                value={_.first(topAppData) ? _.first(topAppData).value : "-"}
                infotitle={
                  "Displays the most used app by the employee. Usage duration is also displayed."
                }
                bottomtext={
                  _.first(topAppData) ? _.first(topAppData).bottomtext : "-"
                }
                fulldata={_.first(topAppData) ? _.first(topAppData).data : "-"}
              />

              <ApplicationTopUrl
                key={2}
                label={"Top URL"}
                value={_.first(topUrlData) ? _.first(topUrlData).value : "-"}
                infotitle={
                  "The most used URL of the employee. Usage duration is also displayed."
                }
                bottomtext={
                  _.first(topUrlData) ? _.first(topUrlData).bottomtext : "-"
                }
                fulldata={_.first(topUrlData) ? _.first(topUrlData).data : "-"}
              />

              <ApplicationTopCategory
                key={3}
                label={"Top Category"}
                value={
                  _.first(topCategoryData)
                    ? _.first(topCategoryData).value
                    : "-"
                }
                infotitle={
                  "Displays the most used category of apps/URLs by the employee. Usage duration is also displayed."
                }
                bottomtext={
                  _.first(topCategoryData)
                    ? _.first(topCategoryData).bottomtext
                    : "-"
                }
                fulldata={
                  _.first(topCategoryData) ? _.first(topCategoryData).data : "-"
                }
              />
            </>
          </div>
          <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "20px" }}
          >
            <OnlineTimeBreakdown
              graphdata={
                activitysummary &&
                activitysummary.activity_summary_online_time_break_down_data
              }
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            {/* <AWorkingTimeTrends /> */}
            {/* <TeamwiseActiveyTrends /> */}
          </div>
          <div className="modal_table">
            <ActivityTable
              employeeListData={employeeListData && employeeListData}
              duration={dateDiff}
            />
          </div>
        </Content>
        {/* Application content */}
        <Content active={active === 4}>
          <div className="mgrid column_3">
            {/* {appnumberstat.map((nitem, nindex) => {
              return (
                <NumberStats
                  key={nindex}
                  label={nitem.label}
                  value={nitem.value}
                  infotitle={nitem.infotitle}
                  bottomtext={nitem.bottomtext}
                />
              );
            })} */}
            <ApplicationTopApp
              key={1}
              label={"Top Application"}
              value={_.first(topAppData) ? _.first(topAppData).value : "-"}
              infotitle={
                "The most used app of the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topAppData) ? _.first(topAppData).bottomtext : "-"
              }
              fulldata={_.first(topAppData) ? _.first(topAppData).data : "-"}
            />

            <ApplicationTopUrl
              key={2}
              label={"Top URL"}
              value={_.first(topUrlData) ? _.first(topUrlData).value : "-"}
              infotitle={
                "The most used URL of the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topUrlData) ? _.first(topUrlData).bottomtext : "-"
              }
              fulldata={_.first(topUrlData) ? _.first(topUrlData).data : "-"}
            />

            <ApplicationTopCategory
              key={3}
              label={"Top Category"}
              value={
                _.first(topCategoryData) ? _.first(topCategoryData).value : "-"
              }
              infotitle={
                "Displays the most used category of apps/URLs by the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topCategoryData)
                  ? _.first(topCategoryData).bottomtext
                  : "-"
              }
              fulldata={
                _.first(topCategoryData) ? _.first(topCategoryData).data : "-"
              }
            />
          </div>
          <div
            className="mgrid column_3 app_charts"
            style={{ marginTop: "0px", marginBottom: "20px" }}
          >
            <CategoryUtilisation
              applicationCategoryWorkingHoursTime={
                ApplicationCategoryWorkingHoursTime
              }
              applicationCategoryList={ApplicationCategoryList}
              chartTitle={"Category Utilization"}
              legend={false}
              tooltipTitle={
                "Displays the time spent by the employee in apps/URLs of different productivity categories."
              }
              height={"260px"}
            />

            <TotalAppusage
              applicationAppWorkingHoursTime={ApplicationAppWorkingHoursTime}
              applicationAppCategoryList={ApplicationAppCategoryList}
              chartTitle={"Total Application usage"}
              tooltipTitle={
                "Displays the time spent by the employee in apps arranged in descending order of usage duration."
              }
              legend={false}
              height={"334px"}
            />

            <TotalYUrlusage
              applicationUrlWorkingHoursTime={ApplicationUrlWorkingHoursTime}
              applicationUrlCategoryList={ApplicationUrlCategoryList}
              chartTitle={"Total URL usage"}
              tooltipTitle={
                "Displays the time spent by the employee in URLs arranged in descending order of usage duration."
              }
              legend={false}
              height={"334px"}
            />
          </div>
          <div className=" " style={{ marginTop: "0px", marginBottom: "20px" }}>
            <AppUrlLogs />
          </div>
        </Content>
        {/* Break Content */}
        <Content active={active === 7}>
          <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            <BreakTrends hideTooltip={false} />
          </div>
          <div className="modal_table">
            <BreakTable
              startDate={startDate}
              endDate={endDate}
              userIds={userIds}
              groupIds={groupIds}
            />
          </div>
        </Content>
        {/* Scorecard Content */}
        {/* <Content active={active === 8} ref={printRef}> */}
        <Content
          // style={{ margin: "20px 0px 0" }}
          className={ShowDateInDownload ? "bg-white" : ""}
          ref={printRef}
          active={active === 8}
        >
          <div
            className="flex flex-col"
            // style={{ margin: "20px 0px 0" }}
          >
            <div
              className=" "
              style={{ marginTop: "0px", marginBottom: "0px" }}
            >
              <UserInfoDetail id={userIds.length ? userIds[0] : identityId} />
            </div>
            {/* <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            <UserPerformance />
          </div> */}
            <div className="flex flex-col items-start gap-y-8 p-2 self-stretch" />

            <div className="flex p-4 md:p-6 flex-col justify-center items-start gap-6 self-stretch rounded-lg border-[1px] border-solid border-gray-200 bg-white shadow-sm">
              <div className="text-gray-900 font-semibold text-xl">
                Comparative Analysis of User’s Performance
              </div>
              <div className="stats column2 w-full">
                <UserCompairsonTeamWiseRadar
                  userPerformance={userPerformance}
                  teamPerformance={teamPerformance}
                  userComparativeDurationPerformance={
                    userComparativeDurationPerformance
                  }
                  teamComparativeDurationPerformance={
                    teamComparativeDurationPerformance
                  }
                />

                <UserCompairsonCompanyWiseRadar
                  userPerformance={userPerformance}
                  companyPerformance={companyPerformance}
                  userComparativeDurationPerformance={
                    userComparativeDurationPerformance
                  }
                  companyComparativeDurationPerformance={
                    companyComparativeDurationPerformance
                  }
                />
              </div>
              <div className="flex flex-col overflow-hidden justify-center items-start gap-6 self-stretch rounded-lg border-[1px] border-solid border-gray-200 bg-white ">
                {" "}
                <div className="w-full">
                  <UserCompairsonCompanyTeamWiseTable
                    userTeamCompanyPerformance={userTeamCompanyPerformance}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-y-8 p-2 self-stretch" />
            <div className="stats column2">
              <div className="flex flex-col items-start gap-2 flex-1  rounded-lg border-[1px] border-solid border-gray-200 bg-white shadow-sm">
                <UserMonthlyAttendance month={monthSelected} />
              </div>
              <div className="flex flex-col items-start gap-2 flex-1 rounded-lg border-[1px] border-solid border-gray-200 bg-white shadow-sm">
                <UserMonthlyGoal month={monthSelected} />
              </div>
            </div>

            <div className="stats column2">
              <UserActivityTrend
                teamWiseActivityTrendData={teamWiseActivityTrendData}
                employeeListData={employeeListData && employeeListData}
              />
              <UserProducitivtyTrend
                teamWiseProductivityTrendData={teamWiseProductivityTrendData}
                employeeListData={
                  employeeListDataProductivity && employeeListDataProductivity
                }
              />
            </div>
            <div className="flex flex-col items-start p-2 " />

            <div className="stats column2">
              {checkRequiredLicense(APPLICATION) && (
                <div className="flex flex-col h-421 px-4 py-6 items-start gap-2 flex-1 rounded-lg border-[1px] border-solid border-gray-200 bg-white shadow-sm">
                  <UserApplication />
                </div>
              )}
              {checkRequiredLicense(WELLNESS) && (
                <div className="flex flex-col h-421 px-4 py-6 items-start gap-2 flex-1 rounded-lg border-[1px] border-solid border-gray-200 bg-white shadow-sm">
                  <UserWellness
                    wellnessCount={wellnessCount}
                    wellnessAllCount={wellnessAllCount}
                  />
                </div>
              )}
            </div>
            <Divider />
            <div className="flex justify-end w-full mt-2">
              {ShowDateInDownload && (
                <div className="flex items-center">
                  <p className="pr-3">Signed by</p>
                  <img
                    src={CompanyLogo}
                    alt="Company Logo"
                    className="mr-4"
                    onLoad={() => {
                      setThumbLoading(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Content>
        <Content active={active === 5}>
          <Row gutter={20} style={{ marginTop: "-20px", marginBottom: "20px" }}>
            <Col className="gutter-row" xs={24} xl={8}></Col>
            <Col className="gutter-row" xs={24} xl={16}></Col>
          </Row>
        </Content>
      </div>
    </>
  );
};

UserDetail.propTypes = {
  getList: PropTypes.func,
};

export default UserDetail;
